@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.main_container {
  display: flex;
  background: #dcded5;
  border-radius: 10px;
  margin-top: -0.9rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  max-width: 1920px;
}

.items_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  grid-gap: 2rem;
  padding-top: 30px;
  padding-bottom: 30px;
}

.item_card {
  border-radius: 5px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  width: 280px;
  text-align: center;
  box-shadow: 0px 0px 30px 10px #34333370;
  transition: 0.6s;
}

.item_card:hover {
  transform: scale(1.05);
  transition: 0.6s;
}

.item_card .item_img {
  width: 280px;
  height: 130px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid rgb(0, 0, 0);
}

.item_card .item_title {
  color: #000;
  font-family: Outfit;
  font-size: 32px;
  font-weight: 400;
  margin-top: 1px;
  margin-bottom: 10px;
}

.item_card .item_desc {
  color: #000;
  text-align: center;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 250px;
  align-self: center;
  margin-top: -1.5rem;
}

.main_container .next {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.main_container .prev {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.main_container svg {
  transition: 0.1s;
}

.main_container svg:active {
  transition: 0.1s;
  transform: scale(1.3);
}

.purchase_container {
  margin-top: -45px;
  margin-bottom: -25px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}

.bottom_container {
  margin-top: -20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.rating_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  font-family: Outfit;
  font-weight: 500;
  font-size: 14px;
  gap: 5px;
  margin-top: -0.3rem;
}

.price {
  font-family: Outfit;
  font-size: 32px;
  color: black;
}

.add_to_cart button {
  border-radius: 10px;
  border: 2px solid #000000;
  background: #FFF;
  font-family: Outfit;
  font-size: 15px;
  color: #000000;
  cursor: pointer;
  width: 123px;
  height: 34px;
  transition: 0.5s;
  font-weight: 500;
}

.add_to_cart button:hover {
  background: #94BE46;
  color: white;
  border: 2px solid #94BE46;
  transition: 0.6s;
}

@media(max-width:1390px) {
  .main_container {
  max-width: 100%;
  }

  .items_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    grid-gap: 2rem;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media(max-width:780px) {
  .main_container {
    display: flex;
    background: #dcded5;
    border-radius: 10px;
    margin-top: 0rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    max-width: 100%;
  }

  .items_container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-gap: 2rem;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .main_container svg {
    transform: rotate(90deg);
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .main_container svg:active {
    transition: 0.1s;
    transform: rotate(90deg);
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #94BE46;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  font-size: 18px;
  color: #333;
  font-family: outfit;
}
